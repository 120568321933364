
import { Vue, Options } from 'vue-class-component'

@Options({
    name: 'SVGrenderer',
    props: {
        icon: String
    }
})
export default class SvgRenderer extends Vue {}
