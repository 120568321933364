<template>
    <div class="min-h-full">
        <div
            :class="[
                { 'overflow-hidden max-h-screen': isMobileMenuOpen },
                '2xl:container mx-auto h-full font-cormorant'
            ]"
        >
            <Header />
            <div
                :class="[
                    {
                        'overflow-hidden h-screen': isMobileMenuOpen,
                        'md:py-12': routeName !== 'Home',
                        'contact-page': routeName === 'Contacts',
                        'painting-page': routeName === 'Painting'
                    },
                    'row min-height-full'
                ]"
            >
                <router-view />
            </div>
        </div>
        <Footer />
        <MenuMobile v-if="isMobileMenuOpen" />
        <Loader />
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'
import { useRoute } from 'vue-router'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import MenuMobile from '@/components/MenuMobile.vue'

@Options({
    name: 'App',
    computed: {
        ...mapGetters(['isMobileMenuOpen', 'isPreloading']),
        routeName() {
            const router = useRoute()
            return router.name
        }
    },
    components: {
        Header,
        Footer,
        Loader,
        MenuMobile
    }
})
export default class App extends Vue {}
</script>

<style lang="scss">
@import 'assets/styles/main';

.painting-page,
.contact-page {
    height: calc(100vh - #{$header-height} - #{$footer-height});

    @include responsive(md) {
        height: calc(100vh - #{$header-height-xl} - #{$footer-height});
    }
}

.min-height-full {
    min-height: calc(100vh - #{$header-height} - #{$footer-height});

    @include responsive(xl) {
        min-height: calc(100vh - #{$header-height-xl} - #{$footer-height});
    }
}

.contact-page {
    @apply flex items-center justify-center;
}

#app {
    background-color: $lightShades;
    min-height: 100%;
}
</style>
