<template>
    <div class="home">
        <Hero />
        <div class="view max-w-screen-xl m-auto px-8 py-16">
            <div class="categories my-8 md:my-16 md:mx-10">
                <template
                    v-for="(category, index) in categoriesName"
                    :key="`category-${index}`"
                >
                    <div
                        :class="['mb-24 text-center', { 'mt-20': index === 0 }]"
                    >
                        <router-link
                            class="category-link-block lg:flex items-center justify-between w-full"
                            :to="{
                                name: 'Gallery',
                                params: {
                                    category: category.replace(/\s+/g, '-')
                                }
                            }"
                        >
                            <div
                                :class="[
                                    index % 2 === 0
                                        ? 'lg:order-2 lg:float-right'
                                        : 'lg:order-1 lg:float-left',
                                    'm-auto bg-img'
                                ]"
                                :style="{
                                    backgroundImage: `url(${images[index]})`
                                }"
                            ></div>
                            <div
                                :class="[
                                    index % 2 === 0 ? 'order-1' : 'order-2',
                                    'mt-12 description'
                                ]"
                            >
                                <h3 class="mt-4 tracking-wide text-6xl link">
                                    {{ category.toUpperCase() }}
                                </h3>
                            </div>
                        </router-link>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Hero from '@/components/Hero.vue'
import { Options, Vue } from 'vue-class-component'
import { mapGetters, useStore } from 'vuex'

@Options({
    name: 'Home',
    computed: {
        ...mapGetters({
            categoriesName: 'getAllCategoriesName',
            isMobileMenuOpen: 'isMobileMenuOpen'
        })
    },
    components: {
        Hero
    }
})
export default class Home extends Vue {
    store = useStore()
    isMobileMenuOpen!: boolean
    categoriesName!: []
    categoriesNamesDisplay: Array<string> = []
    images: Array<string> = [
        `${process.env.VUE_APP_IMG_URL}/acquaforte/0034-the-king.webp`,
        `${process.env.VUE_APP_IMG_URL}/acrilici-astratti/0253-geometria-alata.webp`,
        `${process.env.VUE_APP_IMG_URL}/acrilici-su-carta-telata/0241-serve-riconnettersi.webp`,
        `${process.env.VUE_APP_IMG_URL}/acrilici-su-tela/0155-il-giardino-dei-cactus-2.webp`,
        `${process.env.VUE_APP_IMG_URL}/acrilici-su-tela/0093-tutto-scorre.webp`
    ]
    mounted() {
        if (this.isMobileMenuOpen) {
            this.store.dispatch('toggleMenuMobile')
        }
    }
    splittedText(category: string) {
        const arrayWords = category.split(' ')
        let htmlTags = ''

        arrayWords.forEach(word => {
            htmlTags += `<span>${word}</span> `
        })
        return htmlTags.toUpperCase()
    }
}
</script>
<style lang="scss" scoped>
.category-link-block {
    margin-top: 2rem;
    width: 100%;

    .bg-img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 0.5rem;
        box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
            0 6.7px 5.3px rgba(0, 0, 0, 0.048),
            0 12.5px 10px rgba(0, 0, 0, 0.06),
            0 22.3px 17.9px rgba(0, 0, 0, 0.072),
            0 41.8px 33.4px rgba(0, 0, 0, 0.086),
            0 100px 80px rgba(0, 0, 0, 0.12);
        min-height: 20rem;
        margin-bottom: 3rem;
        width: 100%;

        @include responsive(s) {
            min-height: 30rem;
            width: 80%;
        }

        @include responsive(md) {
            min-height: 50rem;
        }

        @include responsive(lg) {
            min-height: 30rem;
            width: 60rem;
        }

        @include responsive(lg) {
            height: 30rem;
            margin-bottom: 0;
            transition: transform 0.5s ease-in;
            width: 50rem;
        }
    }

    .link {
        display: inline;
    }

    @include responsive(lg) {
        .description {
            max-width: 50%;
            width: calc(100% - 50rem);
        }

        &:hover {
            .bg-img {
                transform: scale(1.02) translateY(-5px);
            }

            .link {
                background-size: 100% 0.3rem;
            }
        }
    }
}
</style>
