
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'
import { useRoute } from 'vue-router'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import MenuMobile from '@/components/MenuMobile.vue'

@Options({
    name: 'App',
    computed: {
        ...mapGetters(['isMobileMenuOpen', 'isPreloading']),
        routeName() {
            const router = useRoute()
            return router.name
        }
    },
    components: {
        Header,
        Footer,
        Loader,
        MenuMobile
    }
})
export default class App extends Vue {}
