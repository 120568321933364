
import { Options, Vue } from 'vue-class-component'
@Options({
    name: 'Hero',
    computed: {
        heroImage() {
            return `${process.env.VUE_APP_IMG_URL}/hero-image-2.jpeg`
        },
        heroVideoUrl() {
            return `${process.env.VUE_APP_IMG_URL}/video-hero.mp4`
        }
    }
})
export default class Hero extends Vue {
    video
    videoWrapper
    videoPlayButton

    mounted() {
        this.videoWrapper = document.getElementsByClassName('video-wrapper')[0]
        this.video = document.getElementsByTagName('video')[0]

        this.renderVideoPlayButton()
    }

    renderVideoPlayButton() {
        if (this.videoWrapper.contains(this.video)) {
            this.formatVideoPlayButton()
            this.video.classList.add('has-media-controls-hidden')
            this.videoPlayButton = document.getElementsByClassName(
                'video-overlay-play-button'
            )[0]
            this.videoPlayButton.addEventListener(
                'click',
                this.hideVideoPlayButton
            )
        }
    }

    formatVideoPlayButton() {
        this.videoWrapper.insertAdjacentHTML(
            'beforeend',
            '\
                <svg class="video-overlay-play-button" viewBox="0 0 200 200" alt="Play video">\
                    <circle cx="100" cy="100" r="90" fill="none" stroke-width="15" stroke="#fff"/>\
                    <polygon points="70, 55 70, 145 145, 100" fill="#fff"/>\
                </svg>\
            '
        )
    }

    hideVideoPlayButton() {
        this.video.play()
        this.videoPlayButton.classList.add('is-hidden')
        this.video.classList.remove('has-media-controls-hidden')
        this.video.setAttribute('controls', 'controls')
    }
}
