<template>
    <footer class="footer row w-full">
        <div class="flex items-center justify-between">
            <div class="flex items-center">
                <small>
                    &copy; Copyright {{ currentYear }} &bull; G. Colombo Art
                </small>
            </div>
            <div class="flex items-center justify-end">
                <a
                    href="tel:+393516263217"
                    aria-label="phone number"
                    rel="noopener noreferrer"
                    target="_blank"
                    class="link-with-icon"
                >
                    <div class="flex items-center">
                        <div class="icon-wrapper">
                            <SvgRenderer icon="phone">
                                <Phone />
                            </SvgRenderer>
                        </div>
                        <small>&nbsp;351 626 3217</small>
                    </div>
                </a>
                &nbsp;&bull;
                <a
                    href="https://www.instagram.com/gcolomboart"
                    aria-label="Instagram"
                    rel="noopener noreferrer"
                    target="_blank"
                    class="icon"
                >
                    <SvgRenderer icon="instagram">
                        <Instagram />
                    </SvgRenderer>
                </a>
            </div>
        </div>
    </footer>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import Instagram from '../assets/svg/instagram.svg'
import Phone from '../assets/svg/phone.svg'
import SvgRenderer from './SvgRenderer.vue'

@Options({
    name: 'Footer',
    components: {
        Instagram,
        Phone,
        SvgRenderer
    }
})
export default class Footer extends Vue {
    currentYear = new Date().getFullYear()
}
</script>
<style lang="scss" scoped>
.footer {
    background-color: darken($lightShades, 3%);
    height: $footer-height;
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: lighten($black, 45%);
}

.icon {
    padding: 5px;
    width: 30px;

    :deep svg {
        fill: #737373;
    }
}
</style>
