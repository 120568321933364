<template>
    <header class="row w-full">
        <nav class="py-6 md:py-8">
            <div class="header-container flex items-center justify-end">
                <div
                    class="flex items-center justify-center header-title ml-10 md:ml-0"
                >
                    <router-link
                        class="leading-relaxed inline-block whitespace-nowrap uppercase font-poiret text-4xl md:text-6xl"
                        :to="{ name: 'Home' }"
                    >
                        Giancarlo <span>Colombo</span>
                    </router-link>
                </div>
                <div
                    class="hidden md:flex items-center header-utility-links justify-end"
                >
                    <ul class="hidden md:flex list-none">
                        <li class="nav-item">
                            <router-link
                                class="leading-relaxed link"
                                :to="{
                                    name: 'Gallery',
                                    params: { category: 'all' }
                                }"
                            >
                                Galleria
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                class="leading-relaxed link"
                                :to="{ name: 'Events' }"
                            >
                                Eventi
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                class="leading-relaxed link"
                                :to="{ name: 'About' }"
                            >
                                Bio
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                class="leading-relaxed link"
                                :to="{ name: 'Contacts' }"
                            >
                                Contatti
                            </router-link>
                        </li>
                    </ul>
                </div>
                <HamburgerMenu class="md:hidden" />
            </div>
        </nav>
    </header>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import HamburgerMenu from './HamburgerMenu.vue'

@Options({
    name: 'Header',
    components: {
        HamburgerMenu
    }
})
export default class Header extends Vue {}
</script>

<style scoped lang="scss">
header {
    height: $header-height;
    @include responsive(xl) {
        height: $header-height-xl;
    }
}

.nav-item {
    padding: 1rem 1.5rem;
}

.header {
    &-container {
        @include responsive(lg) {
            flex: 1 0 67%;
        }
    }

    &-title,
    &-utility-links {
        width: 100%;

        @include responsive(lg) {
            flex: 1 1 30%;
            max-width: calc(100% / 3);
        }
    }
}
</style>
