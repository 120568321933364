
import Hero from '@/components/Hero.vue'
import { Options, Vue } from 'vue-class-component'
import { mapGetters, useStore } from 'vuex'

@Options({
    name: 'Home',
    computed: {
        ...mapGetters({
            categoriesName: 'getAllCategoriesName',
            isMobileMenuOpen: 'isMobileMenuOpen'
        })
    },
    components: {
        Hero
    }
})
export default class Home extends Vue {
    store = useStore()
    isMobileMenuOpen!: boolean
    categoriesName!: []
    categoriesNamesDisplay: Array<string> = []
    images: Array<string> = [
        `${process.env.VUE_APP_IMG_URL}/acquaforte/0034-the-king.webp`,
        `${process.env.VUE_APP_IMG_URL}/acrilici-astratti/0253-geometria-alata.webp`,
        `${process.env.VUE_APP_IMG_URL}/acrilici-su-carta-telata/0241-serve-riconnettersi.webp`,
        `${process.env.VUE_APP_IMG_URL}/acrilici-su-tela/0155-il-giardino-dei-cactus-2.webp`,
        `${process.env.VUE_APP_IMG_URL}/acrilici-su-tela/0093-tutto-scorre.webp`
    ]
    mounted() {
        if (this.isMobileMenuOpen) {
            this.store.dispatch('toggleMenuMobile')
        }
    }
    splittedText(category: string) {
        const arrayWords = category.split(' ')
        let htmlTags = ''

        arrayWords.forEach(word => {
            htmlTags += `<span>${word}</span> `
        })
        return htmlTags.toUpperCase()
    }
}
