<template>
    <span :class="`svg-container ${icon}`">
        <slot></slot>
    </span>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'

@Options({
    name: 'SVGrenderer',
    props: {
        icon: String
    }
})
export default class SvgRenderer extends Vue {}
</script>
<style lang="scss" scoped>
.svg-container {
    position: relative;

    &.expand {
        position: absolute;
        right: 1rem;
        top: 1rem;
    }
}
</style>
