<template>
    <div class="video-wrapper">
        <video width="100%" height="auto" controls :poster="heroImage">
            <source :src="heroVideoUrl" type="video/mp4" />
        </video>
    </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
@Options({
    name: 'Hero',
    computed: {
        heroImage() {
            return `${process.env.VUE_APP_IMG_URL}/hero-image-2.jpeg`
        },
        heroVideoUrl() {
            return `${process.env.VUE_APP_IMG_URL}/video-hero.mp4`
        }
    }
})
export default class Hero extends Vue {
    video
    videoWrapper
    videoPlayButton

    mounted() {
        this.videoWrapper = document.getElementsByClassName('video-wrapper')[0]
        this.video = document.getElementsByTagName('video')[0]

        this.renderVideoPlayButton()
    }

    renderVideoPlayButton() {
        if (this.videoWrapper.contains(this.video)) {
            this.formatVideoPlayButton()
            this.video.classList.add('has-media-controls-hidden')
            this.videoPlayButton = document.getElementsByClassName(
                'video-overlay-play-button'
            )[0]
            this.videoPlayButton.addEventListener(
                'click',
                this.hideVideoPlayButton
            )
        }
    }

    formatVideoPlayButton() {
        this.videoWrapper.insertAdjacentHTML(
            'beforeend',
            '\
                <svg class="video-overlay-play-button" viewBox="0 0 200 200" alt="Play video">\
                    <circle cx="100" cy="100" r="90" fill="none" stroke-width="15" stroke="#fff"/>\
                    <polygon points="70, 55 70, 145 145, 100" fill="#fff"/>\
                </svg>\
            '
        )
    }

    hideVideoPlayButton() {
        this.video.play()
        this.videoPlayButton.classList.add('is-hidden')
        this.video.classList.remove('has-media-controls-hidden')
        this.video.setAttribute('controls', 'controls')
    }
}
</script>
<style lang="scss" scoped>
.full-width-video {
    aspect-ratio: 1280 / 960; /* width and height of the mobile video */
    background-color: black;
    width: 100%;
}
.video-wrapper {
    position: relative;
    margin: 0 auto;
    max-width: 80vw;
}

.video-wrapper > video {
    width: 100%;
    vertical-align: middle;
}

.video-wrapper > video.has-media-controls-hidden::-webkit-media-controls {
    display: none;
}

:deep .video-overlay-play-button {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10px calc(50% - 50px);
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    opacity: 0.95;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
    transition: opacity 150ms;

    &:hover {
        opacity: 1;
    }

    &.is-hidden {
        display: none;
    }
}
</style>
