<template>
    <button
        class="outline-none focus:outline-none w-20 h-20 relative z-40 p-3.5"
        @click="toggleMenu"
        :aria-label="
            !isMobileMenuOpen ? 'Open mobile menu' : 'Close mobile menu'
        "
    >
        <SvgRenderer icon="hamburger">
            <transition name="fade">
                <Hamburger v-show="!isMobileMenuOpen" />
            </transition>
            <transition name="fade">
                <Close v-show="isMobileMenuOpen" />
            </transition>
        </SvgRenderer>
    </button>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { mapGetters, useStore } from 'vuex'
import Close from '../assets/svg/close.svg'
import Hamburger from '../assets/svg/hamburger.svg'
import SvgRenderer from './SvgRenderer.vue'

@Options({
    name: 'HamburgerMenu',
    computed: {
        ...mapGetters(['isMobileMenuOpen'])
    },
    components: {
        Close,
        Hamburger,
        SvgRenderer
    }
})
export default class HamburgerMenu extends Vue {
    store = useStore()
    toggleMenu() {
        this.store.dispatch('toggleMenuMobile')
    }
}
</script>
<style lang="scss" scoped>
.svg-container {
    display: block;
    height: 3.5rem;
    width: 3.5rem;

    svg {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
}
</style>
