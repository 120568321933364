/* eslint-disable @typescript-eslint/no-unused-vars */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'
import store from '@/store/index'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            preload: ['dom'],
            translation: 'Pagina iniziale'
        }
    },
    {
        path: '/gallery/:category',
        name: 'Gallery',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/Gallery.vue'),
        meta: {
            preload: ['dom'],
            translation: 'Galleria'
        }
    },
    {
        path: '/painting/:id',
        name: 'Painting',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/Painting.vue'),
        meta: {
            preload: ['dom'],
            translation: 'Painting'
        }
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
            preload: ['dom'],
            translation: 'Bio'
        }
    },
    {
        path: '/contacts',
        name: 'Contacts',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/Contacts.vue'),
        meta: {
            preload: ['dom'],
            translation: 'Contatti'
        }
    },
    {
        path: '/events',
        name: 'Events',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/Events.vue'),
        meta: {
            preload: ['dom'],
            translation: 'Eventi'
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { left: 0, top: 0 }
    }
})

trackRouter(router)

// Router guard
router.beforeEach((to, from, next) => {
    // require preload? show preloader
    if (to.meta.preload && from.name !== to.name) {
        store.dispatch('startPreloading', to.meta.preload)
    }

    next()
})

router.beforeResolve((to, from, next) => {
    store.dispatch('stopPreloading', to.meta.preload) // Stop progress bar
    next()
})
export default router
