
import { Vue, Options } from 'vue-class-component'
import Instagram from '../assets/svg/instagram.svg'
import Phone from '../assets/svg/phone.svg'
import SvgRenderer from './SvgRenderer.vue'

@Options({
    name: 'Footer',
    components: {
        Instagram,
        Phone,
        SvgRenderer
    }
})
export default class Footer extends Vue {
    currentYear = new Date().getFullYear()
}
