
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'

interface Parameter {
    category: string
}
interface Route {
    name: string
    params: Parameter
}

@Options({
    name: 'MenuMobile',
    computed: {
        ...mapGetters({
            categoriesName: 'getAllCategoriesName'
        })
    }
})
export default class MenuMobile extends Vue {
    categoriesName!: Array<string>
    capitalizeString(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
}
