
import { Vue, Options } from 'vue-class-component'
import HamburgerMenu from './HamburgerMenu.vue'

@Options({
    name: 'Header',
    components: {
        HamburgerMenu
    }
})
export default class Header extends Vue {}
