export const SET_ACQUAFORTE_ARRAY = 'SET_ACQUAFORTE_ARRAY'
export const SET_ACRILICI_SU_CARTA_TELATA_ARRAY =
    'SET_ACRILICI_SU_CARTA_TELATA_ARRAY'
export const SET_ACRILICI_SU_TELA_ARRAY = 'SET_ACRILICI_SU_TELA_ARRAY'
export const SET_ACRILICI_ASTRATTI_ARRAY = 'SET_ACRILICI_ASTRATTI_ARRAY'
export const SET_CATEGORY_ARRAY = 'SET_CATEGORY_ARRAY'
export const SET_FILTERED_ARRAY = 'SET_FILTERED_ARRAY'
export const SET_PAINTINGS_ARRAY = 'SET_PAINTINGS_ARRAY'
export const SORT_PAINTINGS_BY_CATEGORY = 'SORT_PAINTINGS_BY_CATEGORY'
export const TOGGLE_MENU_MOBILE = 'TOGGLE_MENU_MOBILE'
export const START_PRELOADING = 'START_PRELOADING'
export const UPDATE_PRELOADING = 'UPDATE_PRELOADING'
