<template>
    <div class="pre-loader" v-show="isPreloading">
        <div
            :style="[
                spinnerStyle,
                { backgroundColor: '#f2cd5e' },
                { animationDelay: `${delay}s` }
            ]"
        ></div>
        <div
            :style="[
                spinnerStyle,
                { backgroundColor: '#f2a74b' },
                { animationDelay: `${delay * 2}s` }
            ]"
        ></div>
        <div
            :style="[
                spinnerStyle,
                { backgroundColor: '#f27244' },
                { animationDelay: `${delay * 3}s` }
            ]"
        ></div>
    </div>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { mapGetters } from 'vuex'

@Options({
    name: 'Loader',
    computed: {
        ...mapGetters({
            isPreloading: 'isPreloading'
        })
    }
})
export default class Loader extends Vue {
    spinnerStyle = {
        height: '2rem',
        width: '2rem',
        margin: '0 0.5rem',
        borderRadius: '50%',
        display: 'inline-block',
        animationName: 'syncStretchDelay',
        animationDuration: '0.6s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'ease-in-out',
        animationFillMode: 'both'
    }
    delay = 0.07
}
</script>
<style lang="scss" scoped>
.pre-loader {
    align-items: center;
    background: $lightShades;
    display: flex;
    height: 100vh;
    justify-content: center;
    pointer-events: none;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100vw;
}
</style>
