/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore } from 'vuex'
import PaintingModel from './models/PaintingModel'
import artworks from '../data/artworks_real.json'

const PRELOADER_MIN_DELAY = 1000

import {
    SET_FILTERED_ARRAY,
    SET_PAINTINGS_ARRAY,
    TOGGLE_MENU_MOBILE,
    START_PRELOADING,
    UPDATE_PRELOADING
} from './mutation-types'

export default createStore({
    state: {
        categoriesName: [
            'acquaforte',
            'acrilici astratti',
            'acrilici su carta telata',
            'acrilici su tela'
        ],
        filteredArray: Array<PaintingModel>(),
        isMobileMenuOpen: false,
        paintings: Array<PaintingModel>(),
        activeElems: [],
        timerStart: 0
    },
    getters: {
        getAllPaintings: state => state.paintings,
        getAllCategoriesName: state => state.categoriesName,
        getPaintingsByCategory: state => (category: string) => {
            if (
                !category.includes('astratti') &&
                category.includes('acrilici')
            ) {
                category = category.replace('acrilici', 'acrilico')
            }

            return state.paintings.filter(
                painting => painting.category === category
            )
        },
        getFilteredArray: state => state.filteredArray,
        isMobileMenuOpen: state => state.isMobileMenuOpen,
        isPreloading: state => state.activeElems.length !== 0
    },
    mutations: {
        [SET_FILTERED_ARRAY](state, array) {
            state.filteredArray = array
        },
        [SET_PAINTINGS_ARRAY](state, payload) {
            state.paintings = payload
        },
        [TOGGLE_MENU_MOBILE](state) {
            state.isMobileMenuOpen = !state.isMobileMenuOpen
        },
        [START_PRELOADING](state, elems) {
            state.timerStart = Date.now()
            state.activeElems = elems
        },
        [UPDATE_PRELOADING](state, elems) {
            state.activeElems = elems
        }
    },
    actions: {
        setFilteredArray({ commit }, array) {
            commit(SET_FILTERED_ARRAY, array)
        },
        async setPaintingsArray({ commit }) {
            commit(SET_PAINTINGS_ARRAY, artworks)
        },
        toggleMenuMobile({ commit }) {
            commit(TOGGLE_MENU_MOBILE)
        },
        startPreloading({ commit }, elem) {
            commit(START_PRELOADING, elem)
        },
        stopPreloading({ commit, state }, elem) {
            const remainingElems = state.activeElems.filter(el => {
                return el !== elem[0]
            })

            if (remainingElems.length > 0) {
                commit(UPDATE_PRELOADING, remainingElems)
            } else {
                const timeSpent = Date.now() - state.timerStart

                if (timeSpent <= PRELOADER_MIN_DELAY) {
                    setTimeout(() => {
                        commit(UPDATE_PRELOADING, remainingElems)
                    }, PRELOADER_MIN_DELAY - timeSpent)
                } else {
                    commit(UPDATE_PRELOADING, remainingElems)
                }
            }
        }
    },
    modules: {}
})
