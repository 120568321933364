<template>
    <div
        class="fixed overflow-hidden bg-white flex flex-col justify-center left-0 top-0 w-screen h-screen md:hidden z-20"
    >
        <template
            v-for="(route, index) in $router.options.routes"
            :key="`route-${index}`"
        >
            <div
                class="inline-block text-center"
                v-if="route.name !== 'Painting' && route.name !== 'Gallery'"
            >
                <router-link :to="{ name: route.name }">
                    {{ route.meta.translation }}
                </router-link>
            </div>
            <template v-if="route.name === 'Gallery'">
                <div
                    class="inline-block text-center"
                    v-for="(category, index) in categoriesName"
                    :key="`category-${index}`"
                >
                    <router-link
                        :to="{
                            name: 'Gallery',
                            params: {
                                category: category.replace(/\s+/g, '-')
                            }
                        }"
                    >
                        <template v-if="category === 'all'">
                            Tutti
                        </template>
                        <template v-else>
                            {{ capitalizeString(category) }}
                        </template>
                    </router-link>
                </div>
            </template>
        </template>
    </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'

interface Parameter {
    category: string
}
interface Route {
    name: string
    params: Parameter
}

@Options({
    name: 'MenuMobile',
    computed: {
        ...mapGetters({
            categoriesName: 'getAllCategoriesName'
        })
    }
})
export default class MenuMobile extends Vue {
    categoriesName!: Array<string>
    capitalizeString(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
}
</script>
<style lang="scss" scoped></style>
