
import { Vue, Options } from 'vue-class-component'
import { mapGetters } from 'vuex'

@Options({
    name: 'Loader',
    computed: {
        ...mapGetters({
            isPreloading: 'isPreloading'
        })
    }
})
export default class Loader extends Vue {
    spinnerStyle = {
        height: '2rem',
        width: '2rem',
        margin: '0 0.5rem',
        borderRadius: '50%',
        display: 'inline-block',
        animationName: 'syncStretchDelay',
        animationDuration: '0.6s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'ease-in-out',
        animationFillMode: 'both'
    }
    delay = 0.07
}
